let wrapper=(val)=>val.toString().length<2?"0"+val:""+val;

let invalidDate=(date)=>isNaN(date.getTime());

let getDuration=(timediff)=>{
    if(isNaN(Number(timediff)))throw new Error("Invalid Time difference in dateSupport.js at line 6.");
    let duration=Number(timediff);
    let ss = parseInt((duration / 1000) % 60)
    let mm = parseInt((duration / (1000 * 60)) % 60)
    let hh = parseInt((duration / (1000 * 60 * 60)) % 24);

    return `${wrapper(hh)}:${wrapper(mm)}:${wrapper(ss)}`;
}

//get current Date in String Format...
let dateToString=(date)=>{

if(date instanceof Date){

let [year,month,day,hours,minutes,seconds]=['getFullYear','getMonth','getDate','getHours','getMinutes','getSeconds'].map(key=>date[key]());

month++;

return `${year}-${wrapper(month)}-${wrapper(day)} ${wrapper(hours)}:${wrapper(minutes)}:${wrapper(seconds)}`;

}
else return dateToString(new Date());

}

const formatDateString = ( dateString = '' ) => ( (typeof dateString === 'string' ) ? dateString.replace( /-/g, '/' ) : dateString );

//get String to Date Format...
let stringToDate=(string)=>{
    if(!string)return new Date(0);
    let date=new Date( formatDateString( string ) );
    if(invalidDate(date))throw new Error('Invalid Date String passed in dateSupport.js line 22');
    else return date;
}

//utc adjustment.
let getLocalfromUTC=(string)=>{
  let date=new Date( formatDateString( string ) );
  if(isNaN(date.getTime()))throw new Error("Invalid date string passed in dateSupport.js method getLocalfromUTC");
  let offset=new Date().getTimezoneOffset();
  date.setMinutes(date.getMinutes()-offset);
  return date;
}


const getStartDate=(string)=>{

        let startDate = null;
  
        let currentDate = new Date();
        let fromDate = new Date( formatDateString( string ) );
        if (fromDate > currentDate) {
          let oneDay = 24 * 60 * 60 * 1000;
          let diffDays = Math.floor((fromDate.getTime() - currentDate.getTime()) / oneDay);  
  
          if (diffDays === 0) {
            let diff = fromDate.getTime() - currentDate.getTime();
            
            startDate = 'Starts in ' + getDuration(diff);

          } else if (diffDays > 0) {
            let day = fromDate.getDate()
            let month = fromDate.toLocaleString('default', { month: 'long' });
            startDate = 'Starts on ' + day + ' ' + month;
            
          }
        }

        return startDate;
      
}

const checkEventEnded=(expiryDateString)=>{
  if(!expiryDateString)return false;
  let expiryDate=getLocalfromUTC(expiryDateString);
  let currentDate=new Date();
  return (currentDate>expiryDate);
}

let currencyPass=(currency,country)=>{
   
   switch(currency){
      case 'INR':
      return country ==='IN';
      case 'ROW':
      return country !=='IN';
      default:
      return country !=='IN';  
    }

}


export {
  dateToString,
  stringToDate,
  getStartDate,
  getLocalfromUTC,
  currencyPass,
  checkEventEnded,
  formatDateString
};