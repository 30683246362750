import Vue from "vue";
import firebase from "firebase/app";
import "firebase/messaging";
import SimpleCrypto from "simple-crypto-js";
import { EventBus } from "@/main";
import store from "../../store";
import {pushConfiguration} from "@/module/vendor-config.js";


const state = {
  country: "",
  ticketResponse: "",
  detailCardStatus: "",
  paymentRetry: "",
  notificationStatus: false,
  plansSubscribedResponse: "",
  fcmNotifications: "",
  currentContent: null
};

const getters = {
  country: state => state.country,
  ticketResponse: state => state.ticketResponse,
  detailCardStatus: state => state.detailCardStatus,
  paymentRetry: state => state.paymentRetry,
  notificationStatus: state => state.notificationStatus,
  plansSubscribedResponse: state => state.plansSubscribedResponse,
  fcmNotifications: state => state.fcmNotifications,
  currentContent: state => state.currentContent
};

const mutations = {
  commitCountry: (state, country) => {
    state.country = country;
  },
  commitTicketResponse: (state, ticketResponse) => {
    state.ticketResponse = ticketResponse;
  },
  commitDetailCardStatus: (state, detailCardStatus) => {
    state.detailCardStatus = detailCardStatus;
  },
  commitPaymentRetry: (state, paymentRetry) => {
    state.paymentRetry = paymentRetry;
  },
  commitNotificationStatus: (state, value) => {
    state.notificationStatus = value;
  },
  commitPlansSubscribedResponse: (state, value) => {
    state.plansSubscribedResponse = value;
  },
  commitFcmNotifications: (state, value) => {
    state.fcmNotifications = value;
  },
  commitCurrentContent: (state, value) => {
    state.currentContent = value;
  }
};

const actions = {


  /**
  * Auto Login
  */
  actUserAutoLogin: (context) => {
    return new Promise(function (resolve, reject) {
      let LoginType = {};
      if (localStorage.getItem("loginType")) {
        LoginType = JSON.parse(localStorage.getItem("loginType"));
      }
      if (LoginType) {
        let data = {};
        switch (LoginType.type) {
          case "EMAIL":
            let simpleCrypto = new SimpleCrypto(store.getters.SECRET_KEY);
            (data.app = "SELF"), (data.email = LoginType.email);
            data.password = simpleCrypto.decrypt(LoginType.password);
            // callLoginApi();
            // EventBus.$emit("loginUser", data);

            break;
          case "FB":
            // FB.getLoginStatus(function(response) {
            //   if (response.status === "connected") {
            //     console.log(response.authResponse.accessToken);
            //     // data.app = "FB";
            //     data.fbtoken = response.authResponse.accessToken;
            //     data.email = LoginType.email;
            //     // EventBus.$emit("loginUser", data);
            //     // callLoginApi();
            //   } else {
            //     FB.login(function(loginresponse) {
            //       if (loginresponse.authResponse) {
            //         // data.app = "FB";
            //         data.fbtoken = loginresponse.authResponse.accessToken;
            //         data.email = LoginType.email;
            //         // EventBus.$emit("loginUser", data);
            //         // callLoginApi();
            //       }
            //     });
            //   }
            // });
            data.fbtoken = LoginType.token;
            data.email = LoginType.email;
            break;
          case "GOOGLE":
            // gapi.auth2
            //   .getAuthInstance()
            //   .currentUser.get()
            //   .reloadAuthResponse()
            //   .then((response) => {
            //     if (response) {
            //       data.app = "GOOGLE";
            //       data.gtoken = response.id_token;
            //       callLoginApi();
            //     }
            //   })
            //   .catch((error) => {
            //     // EventBus.$emit('logOut');
            //   });
            // (data.app = "GOOGLE"), (data.token = LoginType.token);
            data.gtoken = LoginType.token;
            // EventBus.$emit("loginUser", data);
            break;
        }
        // const callLoginApi = () => {
        if (data) {
          context
            .dispatch("actLogin", data)
            .then((response) => {
              if (!response.error) {
                resolve(response);
              } else {
                localStorage.removeItem("appUser");
                localStorage.removeItem("loginType");
                // this.$router.push({ name: "Home" });
                //window.location.reload()
window.location.assign(window.location.origin);
                reject("LoginType Not Found");
              }
            })
            .catch((error) => {
              localStorage.removeItem("appUser");
              localStorage.removeItem("loginType");
              // this.$router.push({ name: "Home" });
              //window.location.reload()
window.location.assign(window.location.origin);
              reject(error);
            });
        }
        // };
      } else {
        localStorage.removeItem("appUser");
        localStorage.removeItem("loginType");
        // this.$router.push({ name: "Home" });
        //window.location.reload()
window.location.assign(window.location.origin);
        reject("LoginType Not Found");

      }
    });
  },

  actLogin: (context, data) => {
    let vendorid = context.getters.vendorId;
    let vendorkey = context.getters.vendorKey;

    if (!(vendorid && vendorkey)) return;
    let baseUrl = context.getters.baseUrl;
    let params = { vendorid, vendorkey, ...data };
    let url = `${baseUrl}/subscriber/v1/login`;

    return new Promise(function (resolve, reject) {
      Vue.http.get(url, { params }).then(response => {
        if (response.ok && response.status === 200) {
          resolve(response.body);
        } else {
          reject({ error: true });
        }
      })
        .catch(error => {
          console.log('>>Login-response-error', error);
          reject(error);
        });
    });
  },
  actSignup: (context, data) => {
    let vendorid = context.getters.vendorId;
    let vendorkey = context.getters.vendorKey;
    if (!(vendorid && vendorkey)) return;

    let baseUrl = context.getters.baseUrl;
    let params = { vendorid, vendorkey, ...data };
    let URL = `${baseUrl}/subscriber/v1/subscriber/subscribe`;
    return new Promise(function (resolve, reject) {
      Vue.http.post(URL, params, { emulateJSON: true }).then(response => {
        if (response.ok && response.status === 200) {
          resolve(response.body);
        } else {
          reject({ error: true });
        }
      })
        .catch(error => {
          reject(error);
        });
    });
  },
  actGetSubscriber: (context, data) => {
    let vendorid = context.getters.vendorId;
    let vendorkey = context.getters.vendorKey;
    if (!(vendorid && vendorkey)) return;

    let baseUrl = context.getters.baseUrl;
    let subscriberUrl = context.getters.subscriberUrl;
    let url = `${baseUrl}/${subscriberUrl}/subscriber`;
    return new Promise(function (resolve, reject) {
      let params = { vendorid, vendorkey, ...data };
      Vue.http.get(url, { params }).then(response => {
        if (response.ok && response.status === 200) {
          resolve(response.body);
        } else {
          reject({ error: true });
        }
      })
        .catch(error => {
          reject(error);
        });
    });
  },
  actLogout: context => {
    let vendorid = context.getters.vendorId;
    let vendorkey = context.getters.vendorKey;

    if (!(vendorid && vendorkey)) return;
    let baseUrl = context.getters.baseUrl;
    let subscriberUrl = context.getters.subscriberUrl;
    let url = `${baseUrl}/${subscriberUrl}/logout`;

    return new Promise(function (resolve, reject) {
      Vue.http.get(url).then(response => {
        if (response.ok && response.status === 200) {
          resolve(response.body);
        } else {
          reject({ error: true });
        }
      })
        .catch(error => {
          reject(error);
        });
    });
  },
  actResetPassword: (context, data) => {
    let baseUrl = context.getters.baseUrl;
    let subscriberUrl = context.getters.subscriberUrl
    let vendorid = context.getters.vendorId;
    let vendorkey = context.getters.vendorKey;
    let params = { vendorid, vendorkey, ...data };
    let URL = `${baseUrl}/${subscriberUrl}/passwordreset`;

    return new Promise(function (resolve, reject) {
      Vue.http.get(URL, { params }).then(response => {
        if (response.ok && response.status === 200) {
          resolve(response.body);
        } else {
          reject({ error: true });
        }
      })
        .catch(error => {
          reject(error);
        });
    });
  },
  actUploadImage: (context, image) => {
    let baseUrl = context.getters.baseUrl;
    let subscriberUrl = context.getters.subscriberUrl;
    let URL = `${baseUrl}/${subscriberUrl}/fileupload`;

    return new Promise(function (resolve, reject) {
      let formData = new FormData();
      formData.append("filename", image);
      Vue.http.post(URL, formData).then(response => {
        if (response.ok && response.status === 200) {
          resolve(response.body);
        } else {
          reject({ error: true });
        }
      })
        .catch(error => {
          reject(error);
        });
    });
  },
  actGetTicket: (context, data={}) => {
    let baseUrl = context.getters.baseUrl;
    let subscriberUrl = context.getters.subscriberUrl;
    let vendorid = context.getters.vendorId;
    let vendorkey = context.getters.vendorKey;
    return new Promise((resolve, reject) => {
      Vue.http.get(baseUrl + `/${subscriberUrl}/ticket`, {
        params: {
          // vendorkey: vendorkey,
          // vendorid: vendorid,
          ...data
        }
      }).then(response => {
        // this.autoLogin(actGetTicket)
        if (response.ok && response.status === 200) {
          resolve(response.body);
        } else {
          // autoLogin(actGetTicket);
          reject({
            error: true,
            status: response.status,
            statusText: response.statusText
          });
        }
      })
        .catch(error => {

          reject(error);
        });
    });
  },
  actProfileUpdate: (context, data) => {
    let vendorid = context.getters.vendorId;
    let vendorkey = context.getters.vendorKey;
    if (!(vendorid && vendorkey)) return;

    let baseUrl = context.getters.baseUrl;
    let URL = `${baseUrl}/subscriber/v1/subscriber/${data.subscriberid}`;
    delete data.subscriberid;

    let params = JSON.stringify({ vendorid, vendorkey, ...data });

    return new Promise(function (resolve, reject) {
      Vue.http.put(URL, params, { emulateJSON: true }).then(response => {
        if (response.ok && response.status === 200) {
          resolve(response.body);
        } else {
          reject({ error: true });
        }
      })
        .catch(error => {
          reject(error);
        });
    });
  },
  actGetPlans: (context, data) => {
    let baseUrl = context.getters.baseUrl;
    let subscriberUrl = context.getters.subscriberUrl;
    let vendorid = context.getters.vendorId;
    let vendorkey = context.getters.vendorKey;
    return new Promise((resolve, reject) => {
      Vue.http.get(baseUrl + `/${subscriberUrl}/userplan/${data.subscriberid}`, {
        params: {
          vendorkey: vendorkey,
          vendorid: vendorid
        },
      })
        .then(response => {
          if (response.ok && response.status === 200) {
            resolve(response.body);
            reject({
              error: true,
              status: response.status,
              statusText: response.statusText
            });
            // }
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  actGetPlansList: (context, data) => {
    let baseUrl = context.getters.baseUrl;
    let subscriberUrl = context.getters.subscriberUrl;
    let vendorid = context.getters.vendorId;
    let vendorkey = context.getters.vendorKey;
    const params = {
      vendorkey: vendorkey,
      vendorid: vendorid
    }
    const affiliatecode = sessionStorage.getItem('promo_code_reference' );
    
    if( affiliatecode ) params.affiliatecode = affiliatecode ;

    return new Promise((resolve, reject) => {
      Vue.http.get(baseUrl + `/${subscriberUrl}/userplan`, {
        params
      }).then(response => {
        if (response.ok && response.status === 200) {
          resolve(response.body);
        } else {
          reject({
            error: true,
            status: response.status,
            statusText: response.statusText
          });
        }
      })
        .catch(error => {
          reject(error);
        });
    });
  },
  actGetCategory: (context, data) => {
    let baseUrl = context.getters.baseUrl;
    let subscriberUrl = context.getters.subscriberUrl;
    let vendorid = context.getters.vendorId;
    let vendorkey = context.getters.vendorKey;
    return new Promise((resolve, reject) => {
      Vue.http.get(baseUrl + `/${subscriberUrl}/content/category`, {
        params: {
          vendorkey: vendorkey,
          vendorid: vendorid,
          ...data
        }
      }).then(response => {
        if (response.ok && response.status === 200) {
          resolve(response.body);
        } else {
          reject({
            error: true,
            status: response.status,
            statusText: response.statusText
          });
        }
      })
        .catch(error => {
          reject(error);
        });
    });
  },
  actPayment: (context, data) => {
    let subscriberUrl = context.getters.subscriberUrl;
    let baseUrl = context.getters.baseUrl;
    let params = { ...data };
    const affiliatecode = sessionStorage.getItem('promo_code_reference' );
    
    if( affiliatecode ) params.affiliatecode = affiliatecode ;

    let URL = `${baseUrl}/${subscriberUrl}/payment/init`;
    return new Promise(function (resolve, reject) {
      Vue.http.post(URL, params, { emulateJSON: true }).then(response => {
        if (response.ok && response.status === 200) {
          sessionStorage.removeItem( 'promo_code_reference' );
          resolve(response.body);
        } else {
          reject({
            error: true,
            status: response.status,
            statusText: response.statusText
          });
        }
      })
        .catch(error => {
          reject(error);
        });
    });
  },
  paypalCallBack: (context, data) => {
    let params = { ...data };
    let subscriberUrl = context.getters.subscriberUrl;
    let baseUrl = context.getters.baseUrl;
    let URL
    if(data.url){
       URL = data.url;
      delete data.url;
    }
    else{
      URL = `${baseUrl}/${subscriberUrl}/payment/callback`;
    }
    return new Promise(function (resolve, reject) {
      Vue.http.post(URL, params, { emulateJSON: true }).then(response => {
        if (response.ok && response.status === 200) {
          resolve(response.body);
        } else {
          reject({
            error: true,
            status: response.status,
            statusText: response.statusText
          });
        }
      })
        .catch(error => {
          reject(error);
        });
    });
  },
  initFCMNotifications(context) {
    let state = context;
    // Retrieve Firebase Messaging object.
    let secondaryFirebase = firebase.app("secondary");
    const messaging = secondaryFirebase.messaging();


    if (navigator.serviceWorker && ('Notification' in window)) {
      navigator.serviceWorker.register("/static/sw.js?"+pushConfiguration.map(attr=>attr.join("=")).join("&")).then((registration) => {
        console.log("service worker ");
        //messaging.useServiceWorker(registration);

        Notification.requestPermission()
          .then(() => {
            messaging
              .getToken({
                vapidKey: store.getters.FirebaseConfig.certificateKey,
                serviceWorkerRegistration:registration
              })
              .then((token) => {
                localStorage.setItem("messageToken", token);
                // notification
                let notificationStatus = localStorage.getItem(
                  "notificationStatus"
                );
                if (
                  notificationStatus !== null &&
                  JSON.parse(notificationStatus)
                ) {
                  state.dispatch("subscribeVendorAndSubscriberTopics");
                } else {
                  if (notificationStatus === null || JSON.parse(notificationStatus) === false) {
                    localStorage.setItem(
                      "notificationStatus",
                      JSON.stringify(true)
                    );
                    state.commit("commitNotificationStatus", true);

                    state.dispatch("subscribeVendorAndSubscriberTopics");
                  }
                }
              })
              .catch((err) => {
                console.log("[err]", err);
              });
          })
          .catch((err) => {
            localStorage.setItem(
              "notificationStatus",
              JSON.stringify(false)
            );
            state.commit("commitNotificationStatus", false);

            console.log("[err]", err);
          });

        // messaging.onMessage((payload) => {
        navigator.serviceWorker.addEventListener("message", payload => {
          //if (!store.getters.AppUser) return;
          console.log("[onMessage payload]", payload);
          let payloadData = payload.data.firebaseMessagingData.data;

          let data = {
            data: payloadData,
            subscriberid: state.getters.subscriberId,
            read: false,
            timestamp: new Date(),
            // timestamp: Math.floor(new Date().getTime() / 1000)
          };
          let fcm_notifications = localStorage.getItem("fcm_notifications");
          let notifications;
          if (fcm_notifications) {
            notifications = JSON.parse(fcm_notifications);
            notifications = [...notifications, data];
          } else {
            notifications = [data];
          }
          notifications = notifications.slice().reverse();

          localStorage.setItem(
            "fcm_notifications",
            JSON.stringify(notifications)
          );

          let fcm_notifications_temp = localStorage.getItem("fcm_notifications_temp");
          let notifications_temp;
          if (fcm_notifications_temp) {
            notifications_temp = JSON.parse(fcm_notifications_temp);
            notifications_temp = [...notifications_temp, data];
          } else {
            notifications_temp = [data];
          }
          notifications_temp = notifications_temp.slice().reverse();

          localStorage.setItem(
            "fcm_notifications_temp",
            JSON.stringify(notifications_temp)
          );

          state.commit("commitFcmNotifications", true);

          if (Notification.permission === "granted") {
            let vendorName=pushConfiguration.find(params=>params[0]==='vendorName')?.[1]??'';
            let data = payload.data.firebaseMessagingData.data;
            var title = `${vendorName} Notification`;
            let body = `New message from ${vendorName}`;
            let image = null;
            let tag = null;
            if (payload.data) {
              title = data.title;
              body = data.message;
              image = data.image_url;
              tag = data.contentid ? `detail-card/${data.contentid}` : '';
            }
            var options = {
              body,
              image,
              tag
            };
            registration.showNotification(title, options);
          }
        });
      },
        function (error) {
          console.log("error", error)
        }
      );
    }
    ////
  },
  unsubscribeFromTopics(context, status) {
    let token = localStorage.getItem("messageToken");
    let state = context;
    let url = `${state.getters.FCM_BASE_URL}/:batchRemove`;


    let appConfig = JSON.parse(localStorage.getItem("appConfig"))
    let appConfigVendorId = appConfig.vendorDetails.vendorId;


    if (token) {
      if (status) {
        // localStorage.removeItem('messageToken');
      }
      fetch(url, {
        method: "POST",
        headers: new Headers({
          Authorization: `key=${store.getters.FirebaseConfig.serverKey}`,
        }),
        body: JSON.stringify({
          to: `/topics/${state.getters.FCM_TOPIC_NAME_PREFIX}${state.getters.vendorid
            }`,
          registration_tokens: [token],
        }),
      })
        .catch((error) => {
          console.log("[error]", error);
        });
      fetch(url, {
        method: "POST",
        headers: new Headers({
          Authorization: `key=${store.getters.FirebaseConfig.serverKey}`,
        }),
        body: JSON.stringify({
          to: `/topics/${state.getters.FCM_TOPIC_NAME_PREFIX}${state.getters.vendorId
            }_${state.getters.subscriberId}`,
          registration_tokens: [token],
        }),
      })
        .then(() => {
          // localStorage.removeItem('messageToken');
          localStorage.setItem("notificationStatus", false);
          store.commit("commitNotificationStatus", false);
        })
        .catch((error) => {
          console.log("[error]", error);
        });
    }
  },

  subscribeVendorAndSubscriberTopics(context) {
    let token = localStorage.getItem("messageToken");
    let state = context;

    let appConfig = JSON.parse(localStorage.getItem("appConfig"))
    let appConfigVendorId = appConfig.vendorDetails.vendorId;

    if (token) {
      localStorage.setItem("notificationStatus", true);
      ///
      const listenToSubscriberNotification = () => {
        let url = `${state.getters.FCM_BASE_URL}/${token}/rel/topics/${state.getters.FCM_TOPIC_NAME_PREFIX
          }${state.getters.vendorId}`;


        fetch(url, {
          method: "POST",
          headers: new Headers({
            Authorization: `key=${store.getters.FirebaseConfig.serverKey}`,
          }),
        })
          .catch((error) => {
            console.log("[error]", error);
          });

        let subscriberUrl = `${state.getters.FCM_BASE_URL
          }/${token}/rel/topics/${state.getters.FCM_TOPIC_NAME_PREFIX}${state.getters.vendorId
          }_${state.getters.subscriberId}`;


        fetch(subscriberUrl, {
          method: "POST",
          headers: new Headers({
            Authorization: `key=${store.getters.FirebaseConfig.serverKey}`,
          }),
        })
          .catch((error) => { console.log(error) });
      };
      if (state.getters.subscriberId) {
        listenToSubscriberNotification();
      }
    }
  },
};

export const User = { state, getters, mutations, actions };
